import React from 'react';
import { NextLink } from 'components/blocks/NextLink';
import styles from 'components/common/FeaturedCamera/CameraViewTitle.module.scss';

interface ICameraViewTitleProps {
  href?: string
  title?: string
  date?: string

}
const CameraViewTitle = ({ href, title, date }: ICameraViewTitleProps) => {
  const children = (
    <>
      <span>{title}</span>
      {date && (<span>{date}</span>)}
    </>
  );

  if (href) {
    return (
      <NextLink className={styles.title} href={href}>
        {children}
      </NextLink>
    );
  }

  return (
    <div className={styles.title}>
      {children}
    </div>
  );
};

export default CameraViewTitle;
