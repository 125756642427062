import classNames from 'classnames';
import { IWebcamItem, WebcamType } from 'api/region/types';
import { NextLink } from 'components/blocks/NextLink';
import { getLang } from 'i18n/locale';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { OneTrustCategories } from 'onetrust/constants';
import {
  RefObject, useEffect, useRef,
  useState,
} from 'react';
import { formatAsTodYestTomExact } from 'util/formatAgo';
import { useOneTrustIframe } from 'util/useAddOneTrustIframe';
import { useResponsiveImage } from 'util/useResponsiveImage';
import { BLUR_CONTENT_STYLE_NAME } from 'theme';
import { useIsBrowser } from 'util/useIsBrowser';
import styles from './styles.module.scss';
import CameraViewTitle from './CameraViewTitle';

export interface IPropsFeaturedCamera {
  cam?: IWebcamItem,
  href?: string,
  title?: string,
  isFresh: boolean,
  refScroll?: RefObject<HTMLDivElement>,
  className?: string,
  showDate?: boolean,
  hideTitle?: boolean
}

export const FeaturedCamera = (
  {
    title, cam, href, isFresh, refScroll, className, showDate, hideTitle,
  }: IPropsFeaturedCamera,
) => {
  // state to trigger rerender
  const [, setPlaceholder] = useState(false);
  const { t } = useOTSTranslation('common');
  const bgImage = useResponsiveImage(cam?.thumbnail || cam?.image || '', cam?.thumbnail || '');
  let videoStream = cam?.videoStream;
  if (videoStream && cam?.webcamType === WebcamType.Feratel) {
    videoStream += `&lg=${getLang()}`;
  }
  const videoIframe = useRef<HTMLDivElement>(null);
  useOneTrustIframe(videoIframe?.current || null, videoStream || '', title || cam?.title || '', OneTrustCategories.TargetingCookies);
  const isBrowser = useIsBrowser();

  const cameraView = (
    <>
      {isBrowser && (
      <figure
        className={classNames(
          styles.box,
          {
            [BLUR_CONTENT_STYLE_NAME]: !isFresh,
            [styles.iframe]: !!videoStream,
            // [styles.noTop]: true,
          },
          !!videoStream && cam?.webcamType === WebcamType.Feratel
            ? styles.feratel
            : styles.webcam360,
          className,
        )}
        style={{
          backgroundImage: !videoStream ? `url(${bgImage})` : 'none',
        }}
        title={t('resort.skiReport.heroTitle')}
        ref={refScroll}
      >
        {!!videoIframe && videoStream && <div ref={videoIframe} />}
        {!hideTitle && (
        <figcaption>
          <CameraViewTitle
            title={title || cam?.title}
            date={cam && showDate ? formatAsTodYestTomExact(cam.date, t) : undefined}
            href={href}
          />
        </figcaption>
        )}

      </figure>
      )}
    </>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoIframe.current) {
        setPlaceholder((prev) => !prev);
      }
    }, 1000);
    if (videoIframe.current) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  },
  []);

  return href && !videoStream
    ? (
      <NextLink href={href}>
        {cameraView}
      </NextLink>
    )
    : cameraView;
};
