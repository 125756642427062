import { IWebcamItem } from 'api/region/types';
import { IResortPathInfo } from 'api/resort/types';
import { NextLink } from 'components/blocks/NextLink';
import { SnowUnit } from 'components/blocks/Unit';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { Routes } from 'routes';
import { formatAsTodYestTomExact } from 'util/formatAgo';
import classNames from 'classnames';
import { useCallback, useRef } from 'react';
import { useVisibilityWindow } from 'util/useVisibilityWindow';
import { BLUR_CONTENT_STYLE_NAME } from 'theme';
import { useIsMobile } from 'util/useIsMobile';
import styles from './styles.module.scss';
import { FeaturedCamera } from '../FeaturedCamera';

export type IPropsWebcamInfo = {
  blur?: boolean;
  showSnowReport?: boolean;
  showSummitDepth?: boolean;
  cam?: IWebcamItem & { resort?: IResortPathInfo },
  resort?: IResortPathInfo,
  title?: string,
  subtitle?: string,
  onClick?: (cam?: IWebcamItem & { resort?: IResortPathInfo })=>void,
  titleText?: string,
  isCurrent?: boolean,
  imageLink?: (r: IResortPathInfo)=>string,
  titleLink?: (r: IResortPathInfo)=>string,
  startAllLiveOnMobile?:boolean,
};

export const WebcamInfo = (
  {
    blur,
    cam,
    resort,
    subtitle,
    title,
    onClick,
    titleText,
    isCurrent,
    showSnowReport,
    showSummitDepth,
    imageLink = Routes.resortCams,
    titleLink = Routes.resortCams,
    startAllLiveOnMobile,
  }: IPropsWebcamInfo,
) => {
  if (!title && !resort) {
    // eslint-disable-next-line no-console
    console.warn('Invalid usage of WebcamInfo, should have title or resort info');
  }
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useVisibilityWindow(ref, true);

  const { t } = useOTSTranslation('common');

  const snowReport = (showSnowReport && cam?.snow?.snow) ? (
    <figcaption>
      <SnowUnit values={cam.snow?.snow} />
          &nbsp;
      {formatAsTodYestTomExact(cam.snow?.date, t)}
    </figcaption>
  ) : null;

  const summitDepth = (showSummitDepth && cam?.summitSnowDepth) ? (
    <figcaption>
      <SnowUnit values={cam?.summitSnowDepth} />
    </figcaption>
  ) : null;

  const thumb = isVisible ? (cam?.thumbnail || cam?.image) : null;

  const style = thumb ? { backgroundImage: `url(${thumb})`, backgroundSize: 'cover' } : { };

  const r = cam?.resort || resort;

  const isMobile = useIsMobile();

  const getHideOnMobileStyle = (className?: string) => classNames(startAllLiveOnMobile ? [className, styles.hideOnMobile] : className);

  const onClickCb = useCallback(() => {
    if (onClick) {
      onClick(cam);
    }
  }, [cam, onClick]);
  return (
    <div
      className={classNames(styles.box, isCurrent ? styles.isCurrent : '', blur ? BLUR_CONTENT_STYLE_NAME : '')}
      title={titleText}
      ref={ref}
    >
      {
         (r ? (
           <NextLink href={imageLink(r)} className={getHideOnMobileStyle(styles.linkResort)}>
             <figure style={style} tabIndex={-1} title={r.title || title}>
               {snowReport || summitDepth}
             </figure>
           </NextLink>
         ) : (
         // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
           <figure style={style} className={getHideOnMobileStyle()} onClick={onClickCb} role="button" tabIndex={-1} title={title}>
             {snowReport || summitDepth}
           </figure>
         ))
      }
      {r && (
        <NextLink className={getHideOnMobileStyle()} href={titleLink(r)}>
          <span className={classNames('h5', styles.title)}>{r.title}</span>
          {subtitle && <span className={classNames('h6', styles.subtitle)}>{subtitle}</span>}
        </NextLink>
      )}
      {startAllLiveOnMobile && <FeaturedCamera isFresh cam={cam} hideTitle className={styles.showOnMobile} />}
      {title && !r && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onClick={isMobile ? undefined : onClickCb}>
          <span className={classNames('h5', styles.title)}>{title}</span>
          {subtitle && <span className={classNames('h6', styles.subtitle)}>{subtitle}</span>}
        </div>
      )}
    </div>
  );
};
