import { AdUnitMobileBreakPoint } from 'googlead/GPTAds';
import { useEffect, useState } from 'react';
import { Diagnostics } from 'util/diagnostics';
import { IS_SSR } from 'ots-constants';

export const calcResponsiveImage = (desktopImage: string, mobileImage: string) => {
  if (!global || !global.innerWidth) {
    Diagnostics.error({ tag: 'useResponsiveImage', message: 'useResponsiveImage fired before window existed' });
  }
  if (global && global.innerWidth && global.innerWidth < AdUnitMobileBreakPoint) {
    return mobileImage;
  }
  return desktopImage;
};

export const useResponsiveImage = (desktopImage: string, mobileImage: string) => {
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    if (!IS_SSR) {
      const update = () => {
        const newImage = calcResponsiveImage(desktopImage, mobileImage);
        setImage((img) => {
          if (img !== desktopImage) {
            return newImage;
          }
          return img;
        });
      };
      update();
      window.addEventListener('resize', update);
      return () => {
        window.removeEventListener('resize', update);
      };
    }
    return undefined;
  }, [desktopImage, mobileImage]);

  return image;
};
