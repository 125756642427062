import { locale } from 'i18n/constants';
import {
  DistanceUnitFormatKey,
  TemperatureUnitFormatKey, useDistanceFormatter, useTemperatureFormatter,
} from 'i18n/unitFormat';

export const DistanceUnit = ({
  type, values, formatAll, removeEqual, noValue, separator, formatToLocale,
}:{
  values: Array<number | string | undefined> | number | string | undefined,
  type: DistanceUnitFormatKey,
  noValue?: I18nKeyGenerated['common'],
  separator?: string,
  formatAll?: boolean,
  removeEqual?: boolean,
  formatToLocale?:boolean,
}) => {
  const formatFn = useDistanceFormatter(type);
  const val = formatFn(values, separator, noValue, formatAll, removeEqual);
  if (formatToLocale) {
    try {
      const regExp = /^[0-9]+/;
      const value = val.match(regExp);
      const unitChar = val.split(regExp)[1];

      if (value && unitChar) {
        const localeFormatted = Number(value).toLocaleString([locale, 'en-US']);
        return <>{localeFormatted + unitChar}</>;
      }
    } catch {
      console.error('Incorrect value passed to DistanceUnit: ', val);
    }
  }
  return <>{val}</>;
};

export const TemperatureUnit = ({
  type, values, formatAll, removeEqual, noValue, separator,
}:{
  values: Array<number | string | undefined> | number | string | undefined,
  type: TemperatureUnitFormatKey,
  noValue?: I18nKeyGenerated['common'],
  separator?: string,
  formatAll?: boolean,
  removeEqual?: boolean,
}) => {
  const formatFn = useTemperatureFormatter(type);
  const val = formatFn(values, separator, noValue, formatAll, removeEqual);
  return <>{val}</>;
};

export const SnowUnit = (props:{
  values: Array<number | string | undefined> | number | string | undefined,
  noValue?: I18nKeyGenerated['common'],
  separator?: string,
  format?: 'all' | 'last',
}) => <DistanceUnit type="cmOrInchShort" {...props} formatToLocale />;
